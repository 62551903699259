.ant-layout {
  height: 100%;

  &-header {
    height: @header-height;
    border-bottom: 1px solid #e5e5e5;
  }

  &-content {
    overflow: auto;
  }
}

@layout-body-background: @white;
