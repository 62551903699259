@tabs-title-font-size-lg: 24px;
@tabs-highlight-color: @black;
@tabs-active-color: @black;
@tabs-ink-bar-color: @black;

.ant-tabs-tab-btn {
  font-weight: 600;
  color: @black-30;
  font-size: 24px;
  letter-spacing: 0;

  &:hover {
    color: @primary;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @black;
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}
