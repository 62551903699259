@base-font-size: 16;

.rem(@selector, @pixels) {
  @remValue: round((@pixels / @base-font-size), 3);
  @{selector}: ~'@{remValue}rem';
}

.rem-padding(@top, @right, @bottom, @left) {
  .rem(padding-top, @top);
  .rem(padding-right, @right);
  .rem(padding-bottom, @bottom);
  .rem(padding-left, @left);
}

.rem-padding(@top, @right) {
  .rem-padding(@top, @right, @top, @right);
}

.rem-padding(@all) {
  .rem-padding(@all, @all, @all, @all);
}

.rem-margin(@top, @right, @bottom, @left) {
  .rem(margin-top, @top);
  .rem(margin-right, @right);
  .rem(margin-bottom, @bottom);
  .rem(margin-left, @left);
}

.rem-margin(@top, @right) {
  .rem-margin(@top, @right, @top, @right);
}

.rem-margin(@all) {
  .rem-margin(@all, @all, @all, @all);
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.responsive-container {
  @media screen and (min-width: 990px) {
    .rem-padding(32, 200);
  }

  @media screen and (min-width: 1270px) {
    .rem-padding(32, 400);
  }
}

.clickable {
  cursor: pointer;
}

.highlighted {
  color: @primary;
}

.footer-actions {
  .rem(margin-top, 40);
  display: flex;
  justify-content: center;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
}

.section {
  .rem(margin-bottom, 40);

  &-title {
    .fs-20-600;
  }
}

.heading {
  .fs-40-700;
}

.shadow-card {
  background: #ffffff;
  box-shadow: 0 40px 144px -8px rgba(0, 0, 0, 0.07);
  border-radius: 40px;
}
