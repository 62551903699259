.ant-segmented {
  width: 100%;
  padding: 6px;
  height: 63px;
  border-radius: 12px;

  &-thumb-motion {
    border-radius: 12px;
  }

  &-item {
    flex: 1;
    border-radius: 12px;
  }

  &-group {
    height: 100%;
    border-radius: 12px;
  }

  &-item-label {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
  }
}
