@btn-height-lg: 56px;
@btn-height-sm: 32px;
@btn-primary-bg: @primary;

.ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
