html * {
  font-family: 'Gilroy', sans-serif;
}

h1,
.h1 {
  .rem(font-size, 30);
  font-weight: 700;
  line-height: 120%;
}

h2,
.h2 {
  .rem(font-size, 20);
  font-weight: 700;
  line-height: 120%;
}

h4,
.h4 {
  font-style: normal;
  font-weight: 600;
  .rem(font-size, 18);
  line-height: 150%;
  color: #1a1c1a;
}

p {
  .rem(font-size, 16);
  font-weight: 400;
  line-height: 180%;
}

a {
  color: @primary;
  text-decoration: underline;
}

button {
  .rem(font-size, 14);
  font-weight: 500;
  letter-spacing: 0.05em;
}

.label {
  .rem(font-size, 16);
  font-weight: 400;
  line-height: 140%;
}

.label--small {
  .rem(font-size, 14);
  font-weight: 700;
  line-height: 140%;
}

.label--extra-small {
  .rem(font-size, 12);
  font-weight: 700;
  line-height: 140%;
}

.caption {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: #656565;
}

.bold-16 {
  .fs-16;
  font-weight: 700;
}

.normal-14 {
  .fs-14;
  font-weight: 400;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.label {
  .fw-600;
  .fs-20;
}

.text-title {
  .fs-30;
  .fw-700;
  color: @black;
}

.text-primary {
  .fs-16;
  .fw-400;
  color: @black;
}

.text-secondary {
  .fs-16;
  .fw-400;
  color: @text-secondary;
}

.heading-1 {
  .fs-40-700;
}

.heading-2 {
  .fs-32-700;
}

.heading-3 {
  .fs-24-700;
}
