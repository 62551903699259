@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

.fs-12 {
  .rem(font-size, 12);
  .rem(line-height, 18);
}

.fs-14 {
  .rem(font-size, 14);
  .rem(line-height, 22);
}

.fs-16 {
  .rem(font-size, 16);
  .rem(line-height, 24);
}

.fs-18 {
  .rem(font-size, 18);
  .rem(line-height, 150%);
}

.fs-20 {
  .rem(font-size, 20);
  .rem(line-height, 150%);
}

.fs-24 {
  .rem(font-size, 24);
  .rem(line-height, 150%);
}

.fs-30 {
  .rem(font-size, 30);
  .rem(line-height, 38);
}

.fs-32 {
  .rem(font-size, 32);
  .rem(line-height, 38);
}

.fs-40 {
  .rem(font-size, 40);
  .rem(line-height, 48);
}

.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}

.fs-40-700 {
  .fs-40;
  .fw-700;
}

.fs-32-700 {
  .fs-32;
  .fw-700;
}

.fs-24-700 {
  .fs-24;
  .fw-700;
}

.fs-20-400 {
  .fs-20;
  .fw-400;
}

.fs-20-700 {
  .fs-20;
  .fw-700;
}

.fs-16-700 {
  .fs-16;
  .fw-700;
}

.fs-24-600 {
  .fs-24;
  .fw-600;
}

.fs-20-600 {
  .fs-20;
  .fw-600;
}

.fs-16-600 {
  .fs-16;
  .fw-600;
}

.fs-16-600 {
  .fs-16;
  .fw-600;
}

.fs-14-600 {
  .fs-14;
  .fw-600;
}

.fs-16-400 {
  .fs-16;
  .fw-400;
}

.fs-16-500 {
  .fs-16;
  .fw-500;
}

.fs-14-400 {
  .fs-14;
  .fw-400;
}
