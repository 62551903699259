.ant-modal {
  min-width: 400px !important;

  &-content {
    border-radius: 40px;
  }

  &-close-icon {
    font-size: 24px;
    margin: 24px 24px 0 0;
  }

  &-confirm-btns {
    float: none !important;
    display: flex;
    gap: 16px;

    button {
      height: 56px;
      border-radius: 20px;
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 700;
      padding: 0 2rem;
      min-width: 8.75rem;
      transition: all 200ms ease-in-out;
      flex: 1;
    }
  }
}
