@dropdown-vertical-padding: 20px;

.ant-dropdown-menu {
  background: #ffffff;
  box-shadow: 0 16px 48px -16px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  min-width: 169px;

  .ant-dropdown-menu-item {
    .fs-16-600;
    .rem-padding(20, 22);
    display: flex;
    justify-content: space-between;
    color: @black-30;

    &:not(:last-of-type) {
      border-bottom: 1px solid rgba(@black, 0.1);
    }

    &:hover {
      background-color: transparent;
      color: @black;
    }

    &-selected {
      background-color: transparent;
      color: @black;
    }

    i {
      .fs-20;
    }
  }
}
