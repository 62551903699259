.ant-collapse-item-active {
  box-shadow: 0 40px 144px -8px rgba(0, 0, 0, 0.07);
  border: none !important;
}

.ant-collapse-item {
  background: #FFFFFF;
  border: 1px solid #DEDEDE4A;
  border-radius: 32px !important;
}

.ant-collapse-content {
  border-top: none;
  border-radius: 32px !important;
}

@collapse-content-padding: 0;