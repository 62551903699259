.ant-switch {
  border: 1px solid #aeaeae;
  height: 24px;
  background: #ffffff;

  &:hover {
    border-color: @primary;

    .ant-switch-handle::before {
      background-color: @primary;
    }
  }

  &-checked {
    background-color: @primary;
    border-color: @primary;

    .ant-switch-handle::before {
      background-color: #ffffff;
    }

    &:hover {
      .ant-switch-handle::before {
        background-color: #ffffff;
      }
    }
  }

  &-disabled {
    background-color: #ffffff;
    border: 1px solid @stroke;

    .ant-switch-handle::before {
      background-color: #ffffff;
      border: 1px solid @stroke;
    }

    &:hover {
      border: 1px solid @stroke;

      .ant-switch-handle::before {
        background-color: #ffffff;
        border: 1px solid @stroke;
      }
    }
  }

  &-handle {
    top: 3px;
    left: 4px;
    width: 16px;
    height: 16px;

    &::before {
      background-color: @black;
    }
  }
}
