@import (reference) "styles";

@primary-color: @primary;

.ant-checkbox + span {
  .rem(padding-left, 16);
}

.checkbox {
  .fs-16;
}

// ant layout
@layout-header-height: 96px;
@layout-header-background: @white;
@layout-header-padding: 0 48px;