@input-height-lg: @input-height;
@input-bg: @input-bg-c;
@input-border-color: @input-border-c;

input::placeholder {
  color: @black-30;
}

textarea.ant-input, input.ant-input {
  .rem(padding-left, 20);
  .fs-16;
  border-radius: 20px;
  caret-color: @primary;
  transition: all 0s;

  &:hover {
    background: #ffffff;
    border-color: @input-border-c;
  }
}

.ant-input-affix-wrapper {
  .rem(padding-left, 20);

  &:hover {
    background: #ffffff;
    border-color: @input-border-c;
  }

  & > input.ant-input {
    border-radius: 0;
    background: inherit;
  }
}

.ant-form-item {
  &-explain-connected {
    opacity: unset;
  }

  &-label {
    .fw-600;
  }
}

.ant-input-affix-wrapper {
  border-radius: 20px;

  &:hover {
    border-color: @input-border-c !important;
  }
}

.ant-input-prefix {
  color: @black-30;
}
