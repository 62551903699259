@picker-bg: @input-bg-c;
@picker-border-color: @input-border-c;

.ant-picker {
  border-radius: 20px;

  &:hover {
    background: #ffffff;
    border-color: @input-border-c;
  }
}

.ant-picker-suffix {
  .fs-24;
}
