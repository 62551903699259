@select-border-color: @stroke;
@select-background: @tertiary;
@select-multiple-item-height-lg: 64px;

.cdk-virtual-scroll-viewport {
  min-height: 64px;
}

.ant-select {
  border-radius: 20px;

  &:hover {
    background: #ffffff;
    border-color: @input-border-c;
  }

  &-dropdown {
    border-radius: 20px;
    padding: 0;
  }

  &-disabled {
    background-color: #f5f5f5 !important;
  }

  &-item-option-content {
    .fs-16-600;
  }

  &-selector{
    border-radius: 20px !important;
  }
}

.ant-select-item-option {
  color: @black-50;

  &-selected, &:hover {
    color: @black;
    background-color: transparent;
  }

  &-selected:not(.ant-select-item-option-disabled) {
    background-color: transparent;
  }
}

.ant-select-lg {
  height: @input-height;
  background-color: @input-bg-c;
  border: none;

  .ant-select-selector {
    height: 100% !important;
    background: transparent !important;
    border: none !important;
  }
}

.ant-select-selector .ant-select-selection-item {
  margin: auto 0;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 20px;
}

input.ant-select-selection-search-input {
  height: 100% !important;

  &::placeholder {
    color: red;
  }
}
