@menu-item-active-bg: trasparent;
@menu-item-active-border-width: 0;
@menu-item-font-size: 16;

.ant-menu {
  border-right: none;
}

.ant-menu-title-content {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  line-height: 0;
}

.ant-menu-item {
  .fw-700;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
  height: unset !important;

  i {
    .fs-24;
    .rem(margin-right, 24);
  }
}
